import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentService } from './environment.service';
import { AuthenticationService } from './authentication.service';
import { DataTabelSaleOrderInq, ExportSaleorder, ResentPamApi, ExportSaleorderReqeust } from 'src/app/ord/model/ord100.model';
import { salesOrder } from 'src/app/ord/ordsor/ordsor200/model/sales-order-maintenance-model';
import { CustomerInquiryModel, ItemInfoModel, ItemListModel } from 'src/app/ord/model/sales-order-maintenance-model';
import { CustomerModel, ReceiverModel, SaleOrderDetail } from '../model/saleorder.model';
import { VParameter } from '../model/vparameter';
import { apierrhis100 } from 'src/app/ord/apierrhis/apierrhis100/model/apierrhis-model';
import { CustomerInquiry } from '../components/search-member-klangone/model/search-customer-model';

@Injectable({
  providedIn: 'root'
})
export class SalesOrderrService {

  ORDSOR: string = `${this.evmService.getApiUrl()}/ordsor/saleorder` as string;

  constructor(private http: HttpClient,
    private evmService: EnvironmentService,
    private authService: AuthenticationService) { }

  getAllSalesOrderInq(param): Observable<DataTabelSaleOrderInq> {
    const URL = `${this.ORDSOR}/getSaleOrderInquiry`;
    return this.http.post<DataTabelSaleOrderInq>(URL,param ,{headers: this.authService.tokenHeader()});
  }

  getSalesOrdeMainByNumberOrder(numberOrder):Observable<salesOrder> {
    const URL = `${this.ORDSOR}/getSaleOrderBynumberOrder/${numberOrder}`;
    return this.http.get<salesOrder>(URL, {headers: this.authService.tokenHeader()});
  }

  getItemList(ItemList):Observable<ItemListModel>{
    const URL = `${this.ORDSOR}/getItemList`;
    return this.http.post<ItemListModel>(URL, ItemList, {headers: this.authService.tokenHeader()});
  }

  getItemInfoByItemCode(itemCode):Observable<ItemInfoModel>{
    const URL = `${this.ORDSOR}/getItemInfoByItemCode/${itemCode}`;
    return this.http.get<ItemInfoModel>(URL, {headers: this.authService.tokenHeader()});
  }

  getCustomerInquiry(customerInquiryRequest: CustomerInquiry): Observable<CustomerInquiryModel> {
    const URL = `${this.ORDSOR}/getCustomerInqBuyer`;
    return this.http.post <CustomerInquiryModel>(URL, customerInquiryRequest, { headers: this.authService.tokenHeader() });
  }

  getCustomerDetail(customerId):Observable<CustomerModel>{
    const URL = `${this.ORDSOR}/getCustomeDetail/${customerId}`;
    return this.http.get<CustomerModel>(URL, {headers: this.authService.tokenHeader()});
  }

  getReceiverInquiry(customerId):Observable<ReceiverModel>{
    const URL = `${this.ORDSOR}/getReceiverInquiry/${customerId}`;
    return this.http.get<ReceiverModel>(URL, {headers: this.authService.tokenHeader()});
  }

  getReceiverDetail(customerId):Observable<ReceiverModel>{
    const URL = `${this.ORDSOR}/getReceiverDetail/${customerId}`;
    return this.http.get<ReceiverModel>(URL, {headers: this.authService.tokenHeader()});
  }

  getReport(langCode,saleorderNo,userID,userName,warehouseCode) {
    const URL = `${this.ORDSOR}/getReport/${langCode}/${saleorderNo}/${userID}/${userName}/${warehouseCode}`;
    return this.http.get(URL, {headers: this.authService.tokenHeader(),responseType: 'blob'});
    }

  getParameterWmsWareHouse(country):Observable<ItemInfoModel>{
    const URL = `${this.ORDSOR}/getParameterWarehouse/${country}`;
    return this.http.get<ItemInfoModel>(URL, {headers: this.authService.tokenHeader()});
  }

  getSaleOrderExprotReport(param:ExportSaleorderReqeust):Observable<ExportSaleorder>{
    const URL = `${this.ORDSOR}/getSaleOrderExprotReport`;
    return this.http.post<ExportSaleorder>(URL, param,{headers: this.authService.tokenHeader()});
  }

  getBookBankNo(warehouseCode){
    const URL = `${this.ORDSOR}/getBookBank/${warehouseCode}`;
    return this.http.get(URL, {headers: this.authService.tokenHeader()});
  }

  getparameterDetailToLable(paramno){
    const URL = `${this.ORDSOR}/getParameter/${paramno}`;
    return this.http.get(URL, {headers: this.authService.tokenHeader()});
  }

  getparameterWmsSeller(){
    const URL = `${this.ORDSOR}/getParameterWmsSeller`;
    return this.http.get(URL, {headers: this.authService.tokenHeader()});
  }

  getparameterMasterAgent(){
    const URL = `${this.ORDSOR}/getParameterMasterAgent`;
    return this.http.get(URL, {headers: this.authService.tokenHeader()});
  }

  getparameterSellerClose(){
    const URL = `${this.ORDSOR}/getParameterMasterSellerClose`;
    return this.http.get(URL, {headers: this.authService.tokenHeader()});
  }

  getparameterVat(country):Observable<VParameter>{
    const URL = `${this.ORDSOR}/getParameterVat/${country}`;
    return this.http.get<VParameter>(URL, {headers: this.authService.tokenHeader()});
  }

  getSaleOrderDetail(saleorderNo,warehouseCode):Observable<SaleOrderDetail>{
    const URL = `${this.ORDSOR}/getSaleOrderDetail/${saleorderNo}/${warehouseCode}`;
    return this.http.get<SaleOrderDetail>(URL, {headers: this.authService.tokenHeader()});
  }

  confirmSaleOrder(prm){
    const URL = `${this.ORDSOR}/confirmSaleOrder`;
    return this.http.post(URL, prm,{headers: this.authService.tokenHeader()});
  }

  cancelSaleOrder(prm){
    const URL = `${this.ORDSOR}/cancelSaleOrder`;
    return this.http.post(URL, prm,{headers: this.authService.tokenHeader()});
  }

  saveSaleOrder(prm){
    const URL = `${this.ORDSOR}/saveorupdateSaleOrder`;
    return this.http.post(URL, prm,{headers: this.authService.tokenHeader()});
  }

  saveCustomerSo(prm){
    const URL = `${this.ORDSOR}/saveorupdateCustomer`;
    return this.http.post(URL, prm,{headers: this.authService.tokenHeader()});
  }

  saveCosignee(prm){
    const URL = `${this.ORDSOR}/saveorupdateCosignee`;
    return this.http.post(URL, prm,{headers: this.authService.tokenHeader()});
  }

  savePaymentHearder(prm){
    const URL = `${this.ORDSOR}/saveHeader`;
    return this.http.post(URL, prm,{headers: this.authService.tokenHeader()});
  }

  savePaymentDetail(prm){
    const URL = `${this.ORDSOR}/saveOrUpdatePaymentDetail`;
    return this.http.post(URL, prm,{headers: this.authService.tokenHeader()});
  }

  getSugguestionBox(prm){
    const URL = `${this.ORDSOR}/getSugguestionBox`;
    return this.http.post(URL, prm,{headers: this.authService.tokenHeader()});
  }

  getPriceItem(prm){
    const URL = `${this.ORDSOR}/getPriceItem`;
    return this.http.post(URL, prm,{headers: this.authService.tokenHeader()});
  }

  saveSolandingUUID(prm){
    const URL = `${this.ORDSOR}/saveSoLandingUUID`;
    return this.http.post(URL, prm,{headers: this.authService.tokenHeader()});
  }

  saveCustomerSeller(customerId,sellerCode){
    const URL = `${this.ORDSOR}/saveCustomerSeller/${customerId}/${sellerCode}`;
    return this.http.get(URL, {headers: this.authService.tokenHeader()});
  }

  genURLSwitchy(prm){
    const URL = `${this.ORDSOR}/saveSwitchyAPI`;
    return this.http.post(URL, prm,{headers: this.authService.tokenHeader()});
  }

  getErrorSaleOrderByCounty(param): Observable<apierrhis100> {
    const URL = `${this.ORDSOR}/getErrorSaleOrderByCounty`;
    return this.http.post<apierrhis100>(URL,param ,{headers: this.authService.tokenHeader()});
  }

  resentPamApiErrorHistory(param: Array<ResentPamApi>){
    const URL = `${this.ORDSOR}/resentPamApiErrorHistory`;
    return this.http.post(URL,param ,{headers: this.authService.tokenHeader()});
  }

  resentPamApi(param: Array<ResentPamApi>){
    const URL = `${this.ORDSOR}/resentPamsApi`;
    return this.http.post(URL,param ,{headers: this.authService.tokenHeader()});
  }
}
