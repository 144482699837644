import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild
} from '@angular/core';
import { SelectItem } from 'primeng/api';
import { ExportExcelService } from 'src/app/shared/services/export-excel.service';
import * as moment from 'moment';
import {
  FormGroup,
  FormControl
} from '@angular/forms';
import { ParameterService } from 'src/app/shared/services/parameter.service';
import { OverlayPanel } from 'primeng/primeng';
import {
  CountInvoce,
  TotalSale
} from 'src/app/shared/model/dashboard-overall.model';
import {
  NumberOfOrder,
  SalesSummarytoChart,
  SalesSummarytoGrid
} from 'src/app/shared/model/dashboard-sale.model';
import { DataBarChartModel } from 'src/app/shared/model/barchart.model';
import { DashboardSaleService } from 'src/app/shared/services/dashboard-sale.service';
import { DashboardService } from 'src/app/shared/services/dshboard.service';
import { ChartColor } from 'src/app/shared/constant/chartcolor.constant';
import { ParameterWmsSerive } from 'src/app/shared/services/parameterWms.service';
import { TranslateService } from '@ngx-translate/core';
import { ParameterEntity } from 'src/app/shared//model/parameterwmsmodel';
import { DecimalData }  from 'src/app/dsh/model/dsh-model'
import { DecimalService } from 'src/app/shared/services/decimal.service';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { decimalTypePurchase, decimalTypeRoundMethod, decimalTypeRoundToValue, decimalTypeSelling } from 'src/app/shared/constant/decimal.constant';
import { DshBarChartSalePeriodDetail, Dshsal100Model, DshSaleOrderDetailOrderData, DshSaleOrderDetailPieChart, ResponseDshPieChartSaleOrderPeriod, ResponseDshSaleOrder, ResponseDshSaleOrderPeriod, ResponseDshTableOrderSaleOrderPeriod, ResponseDshTableSaleOrderPeriod, ResponseSaleSummaryByPeriod, DshSaleOrderDetailTotalData,DshSaleOrderDetailSaleData,SaleSummaryByPeriod, TempDDL } from './model/dshsal100.model';
import { Dshsal100Service } from './service/dshsal100.service';
import { TranslateLablePipe } from 'src/app/shared/pipes/translateLable.pipe';
import { ParameterDetailData } from 'src/app/adm/model/admprm/admprm300.model';
import { ThaiDatePipe } from 'src/app/shared/pipes/thaidate.pipe';
import { TranslateCalendarPipe } from 'src/app/shared/pipes/translate-calendar.pipe';
import { Table } from 'primeng/components/table/table';
@Component({
  selector: 'app-dshsal100',
  templateUrl: './dshsal100.component.html',
  styleUrls: ['./dshsal100.component.scss']
})
export class Dshsal100Component implements OnInit {
  @ViewChild('table1',{static:false}) table1: Table
  @ViewChild('table2',{static:false}) table2: Table
  @ViewChild('table3',{static:false}) table3: Table
  first:number=0;
  last:number=0;

  first2:number=0;
  last2:number=0;

  first3:number=0;
  last3:number=0;

  @ViewChild('dateFilter', undefined) private dateFilter: any;
  page_model: Dshsal100Model = {} as Dshsal100Model;
  // Model Data.
  optionData: SelectItem[];
  totalSale: TotalSale = {} as TotalSale;
  countInvoice: CountInvoce = { countinv: 0, countitem: 0 } as CountInvoce;
  numOfOrder: NumberOfOrder = {} as NumberOfOrder;
  salesDataGrid: SalesSummarytoGrid = {} as SalesSummarytoGrid;
  salesDataChart: SalesSummarytoChart = {} as SalesSummarytoChart;
  salesDshChart: ResponseDshSaleOrder = {} as ResponseDshSaleOrder;
  getSalesData: any = [];
  getSalesDataItem: any = [];
  getSalesDataOrd: any = [];
  // ChartData.
  // chartColors:chartColor ;
  chartPie: any;
  typeChart = 'bar';
  optionsPie: any;
  optionSale: TempDDL[];
  optionStatus: TempDDL[];
  optionPaymentStatus: TempDDL[];
  optionPaymentMethod: TempDDL[];

  // label display Data.
  sumQty: number = 0 as number;
  sumQtyPc: number = 0 as number;
  labelDateFrom: any;
  labelDateTo: any;
  labelDateBar: any;
  labelMonthFrom: any;
  labelMonthTo: any;
  periodLabel: string;
  disableCountry:boolean;
  // Condition for display.
  showLabelToday: boolean = false as boolean;
  IsSearchInv: boolean = false as boolean;
  firstInv: number = 0 as number;
  firstItm: number = 0 as number;
  firstOrd: number = 0 as number;
  saleShow: boolean = true as boolean;
  dataNotFound: boolean = true as boolean;
  dataNotFoundGrid: boolean = true as boolean;
  count: number = 0 as number;
  IsSearchItem: boolean = false as boolean;
  IsSearchOrd: boolean = false as boolean;
  dataNotFoundGrid2: boolean = true as boolean;
  disButtonChart: boolean = true as boolean;
  isRight: boolean = false as boolean;
  changeQuantity: boolean = false as boolean;
  select: any;
  optionCountry: ParameterEntity[];
  defaultPeriod: any;
  country: string;
  currency = { currencyCd: '', currencyDesc: '' };
  dataNotFoundGridOrd: boolean = true as boolean;

  dataToshowChart: DshBarChartSalePeriodDetail[];
  dataToshowChartTotal: DshBarChartSalePeriodDetail[];

  flagShowChartOrd: boolean = false as boolean;
  labelHeaderBar: string = 'สรุปยอดขาย' as string;
  tableHoverItem: DshSaleOrderDetailOrderData[];
  pageName: string;
  seller: any;
  optionValueDefault: any;
  loadingFlag: boolean = false as boolean;

  programId: string = 'P10002' as string;
  selectTotal: any;
  typeChartTotal = 'bar';
  isChange: boolean = false;
  rangeDates: Date[];
  dateFromToPeriod: any = [];
  minDate: Date;
  maxDate: Date;
  dateFromToPeriodData: DataBarChartModel[];
  sellerLength: number;
  paramToSeller:any = {country:true,warehouse:true};
  monthList  = [];
  public decimalData:DecimalData = {} as DecimalData;
  method:string;
  roundToDecimal:number;
  decimal:number = 0;
  private translateLablePipe = new TranslateLablePipe();
  thaidate = new ThaiDatePipe();
  translateCalendarPipe = new TranslateCalendarPipe();
  translateCalendar:any = this.translateCalendarPipe.transform();

  constructor(private dshsalService: DashboardSaleService,
    private dshService: DashboardService,
    private service: Dshsal100Service,
    private parameterService: ParameterService,
    private ParameterWmsSerive: ParameterWmsSerive,
    private autService: AuthenticationService,
    private exportExcelService: ExportExcelService,
    private decimalService: DecimalService,
    private cdRef : ChangeDetectorRef,
    private translateService: TranslateService) {
    this.pageName = localStorage.getItem(this.programId);
    this.getOptionData();
    setTimeout(() => {
      this.translateMessage();
    }, 2000);
    this.page_model = this.service.load;
    this.page_model.form_model.criterial_form.reset();
    this.page_model.sale_sum_data = {
      sale_amt_today: 0,
      sale_amt_this_month: 0,
      sale_amt_three_month: 0,
      sale_amt_this_year: 0,
      sale_doc_today: 0,
      sale_doc_this_month: 0,
      sale_doc_three_month: 0,
      sale_doc_this_year: 0,
      sale_item_total_sku: 0,
      sale_item_avg_sku: 0,
      sale_doc_total: 0,
      current_balance_qty: 0
    } as SaleSummaryByPeriod;
    this.page_model.table_total_data = [] as Array<DshSaleOrderDetailTotalData>;
    this.page_model.table_order_data = [] as Array<DshSaleOrderDetailOrderData>;
    this.page_model.table_sale_data = [] as Array<DshSaleOrderDetailSaleData>;
    }

    translateMessage() {
      let monthLangParam = ['DSHSAL100.M00063','DSHSAL100.M00064','DSHSAL100.M00065','DSHSAL100.M00066','DSHSAL100.M00067','DSHSAL100.M00068',
                            'DSHSAL100.M00069','DSHSAL100.M00070','DSHSAL100.M00071','DSHSAL100.M00072','DSHSAL100.M00073','DSHSAL100.M00074'];
      for (let i = 0; i < monthLangParam.length; i++) {
        this.translateService.get(monthLangParam[i]).subscribe(async res => {
          this.monthList.push(await res);
        });
      }
    }

  selectPeriod() {
    if (this.rangeDates[0] != null && this.rangeDates[1] != null) {
      this.dateFromToPeriod = [];
      this.dateFromToPeriod.push(moment(this.rangeDates[0]).format('YYYY-MM-DD'));
      this.dateFromToPeriod.push(moment(this.rangeDates[1]).format('YYYY-MM-DD'));
      this.switchingTab();
    } else if (this.rangeDates[0] != null && this.rangeDates[1] == null) {
      this.dateFromToPeriod = [];
      this.dateFromToPeriod.push(moment(this.rangeDates[0]).format('YYYY-MM-DD'));
      this.dateFromToPeriod.push(moment(this.rangeDates[0]).format('YYYY-MM-DD'));
      this.switchingTab();
    } else if (this.rangeDates[0] == null && this.rangeDates[1] == null) {
      this.dateFromToPeriod = [];
      this.switchingTab();
    }
  }

  getCurrency() {
    this.ParameterWmsSerive.getParameterWmsBytableNoAndCode(105, this.page_model.form_model.criterial_form.controls['country'].value).subscribe((response) => {
      this.ParameterWmsSerive.getParameterWmsBytableNoAndCode(9146, response[0].parameterData.parameter_v6).subscribe((currencyData) => {
          this.currency.currencyCd = currencyData[0].parameterData.parameter_v1;
          this.currency.currencyDesc =  response[0].parameterData.parameter_v6 ;
      });
    });
  }

  switchingTab() {
    if (this.isChange) {
      this.isChange = false;
      if (this.typeChartTotal == 'line') {
        setTimeout(() => this.changeTypeChart('bar'), 150);
        setTimeout(() => this.changeTypeChart('bar'), 150);
      }
      if (this.typeChartTotal == 'bar') {
        setTimeout(() => this.changeTypeChart('line'), 150);
        setTimeout(() => this.changeTypeChart('line'), 150);
        setTimeout(() => this.changeTypeChart('bar'), 150);
        setTimeout(() => this.changeTypeChart('bar'), 150);
      }
    } else {
      this.isChange = true;
      if (this.typeChart == 'line') {
        setTimeout(() => this.changeTypeChart('bar'), 150);
        setTimeout(() => this.changeTypeChart('bar'), 150);
      }

      if (this.typeChart == 'bar') {
        setTimeout(() => this.changeTypeChart('line'), 150);
        setTimeout(() => this.changeTypeChart('line'), 150);
        setTimeout(() => this.changeTypeChart('bar'), 150);
        setTimeout(() => this.changeTypeChart('bar'), 150);
      }
    }
  }

  async getOptionData() {
    this.parameterService.getvparameterDetailToLable(2000).subscribe((response) => {
      this.optionData = JSON.parse(JSON.stringify(response));
      this.optionData = this.optionData.sort((a, b) =>
        a.value.pmdtbdnumv3 < b.value.pmdtbdnumv3 ? -1 : a.value.pmdtbdnumv3 > b.value.pmdtbdnumv3 ? 1 : 0
      );

      const defaultSelectItem: SelectItem[] = this.optionData.filter((selectItem: SelectItem) => {
        return selectItem.value.parameterDetailPK.pmdtbdtbdentcd == '3M';
      });

      this.optionValueDefault = defaultSelectItem[0].value;
      this.defaultPeriod = this.optionValueDefault;
      this.page_model.form_model.period.controls['period'].setValue(this.defaultPeriod);

    }, err => {
      console.log(err);
    });
  }

  ngOnInit() {
    this.setValueDate();
    this.autService.getParameterWmsCountry().subscribe((response) => {
      this.optionCountry = JSON.parse(JSON.stringify(response));
      if (this.optionCountry.length == 1) {
        this.disableCountry = true;
        this.country = this.optionCountry[0].value;
        this.page_model.form_model.criterial_form.controls['country'].setValue(this.optionCountry[0].value);
        this.paramToSeller = {country:this.country,warehouse:null}
        this.autService.getParameterNewSaleChannel(this.page_model.form_model.criterial_form.controls['country'].value).subscribe((response) => {
          this.optionSale = JSON.parse(JSON.stringify(response));
        }, err => {
          console.log(err);
        });
        this.ParameterWmsSerive.getParameterWmsCheckbox(9141).subscribe((response) => {
          this.optionStatus = JSON.parse(JSON.stringify(response));
        }, err => {
          console.log(err);
        });
        this.ParameterWmsSerive.getParameterWmsCheckbox(9149).subscribe((response) => {
          this.optionPaymentStatus = JSON.parse(JSON.stringify(response));
        }, err => {
          console.log(err);
        });
        this.parameterService.getvparameterDetailToLable(4100).subscribe((response) => {
          this.optionPaymentMethod = JSON.parse(JSON.stringify(response));
        }, err => {
          console.log(err);
        });
        this.getCurrency();
      } else if(this.optionCountry.length > 1){
        this.page_model.form_model.criterial_form.controls['country'].reset();
      } else if(this.optionCountry.length == 0){
        this.page_model.form_model.criterial_form.controls['country'].disable();
        this.disableCountry = true;
      }
    }, err => {
      console.log(err);
    });

    if (window.screen.width <= 640) {
      this.isRight = false;
    } else {
      this.isRight = true;
    }

    this.page_model.form_model.searchInvGroupForm.reset();
    this.page_model.form_model.searchItmGroupForm.reset();
    this.page_model.form_model.searchOrderGroupForm.reset();
  }

  displayItem(event, item: DshSaleOrderDetailOrderData[], overlaypanel: OverlayPanel) {
    this.tableHoverItem = item;
    if (overlaypanel) {
      overlaypanel.hide();
      setTimeout(() => {
        overlaypanel.show(event);
      }, 100);
    }
  }

  private updateTablePagination(table: Table, data: any[], firstVar: string, lastVar: string) {
    if (table && data && data.length > 0) {
      const first = table._first < 0 ? 0 : table._first;
      const last = Math.min(first + table._rows, data.length);
      this[firstVar] = first;
      this[lastVar] = last;
      this.cdRef.detectChanges();
    }
  }

  ngAfterViewChecked() {
    this.updateTablePagination(this.table1, this.page_model.table_order_data, 'first', 'last');
    this.updateTablePagination(this.table2, this.page_model.table_sale_data, 'first2', 'last2');
    this.updateTablePagination(this.table3, this.page_model.table_total_data, 'first3', 'last3');
  }

  async getSeller(seller) {
    var period: ParameterDetailData = this.page_model.form_model.period.controls['period'].value;
    this.page_model.form_model.criterial_form.controls['seller_form'].setValue(seller.seller);
    this.sellerLength = seller.length;
    if (this.page_model.form_model.criterial_form.valid && this.page_model.form_model.criterial_form.controls['country'].value) {
      this.getCurrency();
      this.getFormatNumber();
      this.getDatatoChart(period.pmdtbdtxtv1, period.pmdtbdnumv1, period.parameterDetailPK.pmdtbdtbdentcd);
      this.periodLabel = this.translateLablePipe.transform(period.pmdtbdtbdedesc,period.pmdtbdtbdldesc);
      this.disButtonChart = false;
      this.labelDateFrom = moment().subtract(3, 'month').startOf('month').format('YYYY-MM-DD');
      this.labelDateTo = moment(new Date()).format('YYYY-MM-DD');
      this.getTotalSale();
      this.getAmountOfOrder();
      this.getCountInvoice();
      this.defaultPeriod = this.optionValueDefault;
      this.page_model.uuid = '';
      await this.loadOverAll();
      this.getDatatoTable();
  }
  }

  loadOverAll() {
    this.service.loadOverviewData(this.page_model).subscribe((response:ResponseSaleSummaryByPeriod)=>{
      this.page_model.uuid = response.uuid;
      this.page_model.sale_sum_data = response.data;
    });
  }

  wareHouse(page) {
    this.country = this.page_model.form_model.criterial_form.controls['country'].value;
    this.page_model.form_model.criterial_form.controls['seller_form'].setValue(null);
    this.paramToSeller = {country:this.country, warehouse:null}
    var period: ParameterDetailData = this.page_model.form_model.period.controls['period'].value;
    this.getCurrency();
    this.autService.getParameterNewSaleChannel(this.page_model.form_model.criterial_form.controls['country'].value).subscribe((response) => {
      this.optionSale = JSON.parse(JSON.stringify(response));
    }, err => {
      console.log(err);
    });
    this.ParameterWmsSerive.getParameterWmsCheckbox(9141).subscribe((response) => {
      this.optionStatus = JSON.parse(JSON.stringify(response));
    }, err => {
      console.log(err);
    });
    this.ParameterWmsSerive.getParameterWmsCheckbox(9149).subscribe((response) => {
      this.optionPaymentStatus = JSON.parse(JSON.stringify(response));
    }, err => {
      console.log(err);
    });
    this.parameterService.getvparameterDetailToLable(4100).subscribe((response) => {
      this.optionPaymentMethod = JSON.parse(JSON.stringify(response));
    }, err => {
      console.log(err);
    });
    this.getFormatNumber();
    if (this.sellerLength > 1) {
      this.getDatatoChart(period.pmdtbdtxtv1, period.pmdtbdnumv1, period.parameterDetailPK.pmdtbdtbdentcd);
      this.periodLabel = this.translateLablePipe.transform(period.pmdtbdtbdedesc,period.pmdtbdtbdldesc);
      this.disButtonChart = false;
      this.labelDateFrom = moment().subtract(3, 'month').startOf('month').format('YYYY-MM-DD');
      this.labelDateTo = moment(new Date()).format('YYYY-MM-DD');
      this.getDatatoTable();
      this.getTotalSale();
      this.getAmountOfOrder();
      this.getCountInvoice();
      this.defaultPeriod = this.optionValueDefault;
    } else {
      this.seller = null;
      this.dataNotFoundGridOrd = true;
    }
    this.page_model.sale_sum_data = {
      sale_amt_today: 0,
      sale_amt_this_month: 0,
      sale_amt_three_month: 0,
      sale_amt_this_year: 0,
      sale_doc_today: 0,
      sale_doc_this_month: 0,
      sale_doc_three_month: 0,
      sale_doc_this_year: 0,
      sale_item_total_sku: 0,
      sale_item_avg_sku: 0,
      sale_doc_total: 0,
      current_balance_qty: 0
    } as SaleSummaryByPeriod;
  }

  getFormatNumber(){
      this.parameterService.getvprmdtltolableByCountry(104, this.page_model.form_model.criterial_form.controls['country'].value).subscribe(response => {
      let decimalData = JSON.parse(JSON.stringify(response));
      this.decimalData.dataTypeDecimalPurchase = decimalData.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == decimalTypePurchase);
      this.decimalData.dataTypeDecimalSelling = decimalData.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == decimalTypeSelling);
      this.decimalData.dataTypeDecimalRoundMethod = decimalData.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd ==  decimalTypeRoundMethod);
      this.decimalData.dataTypeDecimalRoundToValue = decimalData.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == decimalTypeRoundToValue );
      this.decimal = this.decimalData.dataTypeDecimalSelling[0].value.pmdtbdnumv1;
      this.method = this.decimalData.dataTypeDecimalRoundMethod[0].value.pmdtbdtxtv1
      this.roundToDecimal = this.decimalData.dataTypeDecimalRoundToValue[0].value.pmdtbdnumv1;
    }, err => {
      console.log(err);
    });
  }
  getTotalSale() {
    this.dshService.getTotalSale(this.country, this.seller).subscribe(response => {
      this.totalSale = response[0];
    });
  }

  getCountInvoice() {
    this.dshService.getCountInvoice(this.country, this.seller).subscribe(response => {
      this.countInvoice = response;
    });
  }

  getAmountOfOrder() {
    this.dshsalService.getNumberOfOrder(this.country, this.seller).subscribe(response => {
      this.numOfOrder = response;
    });
  }

  selectedData(event) {
    this.rangeDates = null;
    this.dateFromToPeriod = [];
    this.IsSearchInv = false;
    this.IsSearchItem = false;
    this.ClearData(1);
    this.ClearData(2);
    this.sumQty = 0;
    this.sumQtyPc = 0;
    this.periodLabel = this.translateLablePipe.transform(event.value.pmdtbdtbdedesc,event.value.pmdtbdtbdldesc);
    this.getDatatoTable();
    setTimeout(() => {
      this.getDatatoChart(event.value.pmdtbdtxtv1, event.value.pmdtbdnumv1, event.value.parameterDetailPK.pmdtbdtbdentcd);
    } ,1000);
    if (event.value.pmdtbdtxtv1 == 'D') {
      if (event.value.pmdtbdnumv1 == 0) {
        this.showLabelToday = true;
        this.labelDateFrom = moment(new Date()).format('YYYY-MM-DD');
      } else if (event.value.pmdtbdnumv1 == 1) {
        this.showLabelToday = true;
        this.labelDateFrom = moment().subtract(1, 'days').format('YYYY-MM-DD');
      } else if (event.value.pmdtbdnumv1 == 7) {
            this.showLabelToday = false;
            this.labelDateFrom = moment().subtract(event.value.pmdtbdnumv1 - 1, 'days').format('YYYY-MM-DD');
            this.labelDateTo = moment(new Date()).format('YYYY-MM-DD');
            this.minDate = new Date(moment().subtract(event.value.pmdtbdnumv1 - 1, 'days').format('YYYY-MMM-DD'));
            this.maxDate = new Date();
      }
    } else if (event.value.pmdtbdtxtv1 == 'Y') {
      this.showLabelToday = false;
      if (event.value.pmdtbdnumv1 == '0' || event.value.pmdtbdnumv1 == '1') {
        if (event.value.pmdtbdnumv1 == '0') {
          this.labelDateFrom = moment().startOf('year').format('YYYY-MM-DD');
          this.labelDateTo = moment(new Date()).format('YYYY-MM-DD');
        } else {
          this.labelDateFrom = moment().startOf('year').format('YYYY-MM-DD');
          this.labelDateTo = moment().endOf('year').format('YYYY-MM-DD');
        }
      } else {
        this.labelDateFrom = moment().subtract(event.value.pmdtbdnumv1, 'year').startOf('year').format('YYYY-MM-DD');
        this.labelDateTo = moment(new Date()).format('YYYY-MM-DD');
      }
      this.minDate = new Date(moment().subtract(event.value.pmdtbdnumv1, 'month').startOf('month').format('YYYY-MM-DD'));
      this.maxDate = new Date(moment(new Date()).endOf('month').format('YYYY-MM-DD'));
    } else {
            this.showLabelToday = false;
            if (event.value.pmdtbdtxtv1 == 'M' && event.value.pmdtbdnumv1 == 1) {
              this.labelDateFrom = moment().subtract(event.value.pmdtbdnumv1, 'month').startOf('month').format('YYYY-MM-DD');
              this.labelDateTo = moment().subtract(event.value.pmdtbdnumv1, 'month').endOf('month').format('YYYY-MM-DD');
              this.minDate = new Date(moment().subtract(event.value.pmdtbdnumv1, 'month').startOf('month').format('YYYY-MM-DD'));
              this.maxDate = new Date(moment().subtract(event.value.pmdtbdnumv1, 'month').endOf('month').format('YYYY-MM-DD'));
            } else {
              this.labelDateFrom = moment().subtract(event.value.pmdtbdnumv1, 'month').startOf('month').format('YYYY-MM-DD');
              this.labelDateTo = moment(new Date()).format('YYYY-MM-DD');
              this.minDate = new Date(moment().subtract(event.value.pmdtbdnumv1, 'month').startOf('month').format('YYYY-MM-DD'));
              this.maxDate = new Date(moment(new Date()).endOf('month').format('YYYY-MM-DD'));
            }
    }
    this.setValueDate();
  }

  ChangeData() {
    if (this.flagShowChartOrd) {
      this.dataToshowChart = this.salesDataChart.saleSumItemPeriodChart;
      this.flagShowChartOrd = false;
      this.labelHeaderBar = this.translateService.instant('DSHSAL100.M00079');
    } else {
      this.dataToshowChart = this.salesDataChart.NumofOrdTopChart;
      this.flagShowChartOrd = true;
      this.labelHeaderBar = this.translateService.instant('DSHSAL100.M00080');
    }
  }

  ChangeQuantity(action : any){
    if (action == 'qty') {
      this.changeQuantity = true;
      this.flagShowChartOrd = true;
    }
    if (action == 'amt') {
      this.changeQuantity = false;
      this.flagShowChartOrd = false;
    }
    var period: ParameterDetailData = this.page_model.form_model.period.controls['period'].value;
    this.getDatatoChart(period.pmdtbdtxtv1, period.pmdtbdnumv1, period.parameterDetailPK.pmdtbdtbdentcd);
  }

  getDatatoChart(datetype, period, typePeriod) {
    var country = this.page_model.form_model.criterial_form.controls['country'].value;
    var seller = this.page_model.form_model.criterial_form.controls['seller_form'].value;

    this.select = { datetype, period, country, typeBar: 'single', showText: this.translateService.instant('DSHSAL100.M00081') };
    this.selectTotal = { datetype, period, country, typeBar: 'single', showText: this.translateService.instant('DSHSAL100.M00081') };

    this.service.loadDshChartData(this.page_model).subscribe((response:ResponseDshSaleOrder)=> {
      if(this.changeQuantity === false){
        this.page_model.bar_sale_data = response.dshBarChart;
        this.page_model.bar_sale_sum_data = response.dshBarSum;
      } else{
        this.page_model.bar_sale_data = response.dshBarQuantityChart;
        this.page_model.bar_sale_sum_data = response.dshBarSum;
      }
      this.dataToshowChart = this.page_model.bar_sale_data;
      this.dataToshowChartTotal = this.page_model.bar_sale_sum_data;

          if (this.dataToshowChart.length === 0) {
            this.dataNotFound = true;
          } else {
            this.dataNotFound = false;
            this.firstInv = 0;
            this.firstItm = 0;
            this.firstOrd = 0;
            this.getDataTopTwenty(this.page_model.pie_sale_data);
          }

          if (this.select.datetype == 'D') {
            this.select.period == 0 || this.select.period == 1 ? this.saleShow = true : this.saleShow = false;
          } else {
            this.saleShow = false;
          }
    });
  }

  getDataTopTwenty(topTwentyDataChart) {
    var country = this.page_model.form_model.criterial_form.controls['country'].value;
    var seller = this.page_model.form_model.criterial_form.controls['seller_form'].value;

    this.service.loadDshPieChartData(this.page_model).subscribe((response:ResponseDshPieChartSaleOrderPeriod)=> {
      this.page_model.pie_sale_data = response.data;
      this.page_model.uuid = response.uuid;

      const sales: any = [];
      const label: any = [];
      this.page_model.pie_sale_data.forEach((element:DshSaleOrderDetailPieChart)=>{
        sales.push(element.total_amount_inc_vat);
        label.push(this.translateLablePipe.transform(element.item_desc_eng, element.item_desc_local));
      });
      this.createChartTopTwenty(label, sales);
    });
  }

  createChartTopTwenty(label: string[], sales: number[]) {
    let legendChart: any;

    if (window.screen.width >= 640 && window.screen.width < 1025) {
      legendChart = false;
    } else if (window.screen.width > 1024) {
      legendChart = 'right';
    }

    let salesData = [];
    sales.forEach(sale => {
      sale = this.decimalService.getRoundingDecimal(sale, this.method, this.roundToDecimal, this.decimal)
      salesData.push(sale);
    });

    this.chartPie = {
      labels: label,
      datasets: [{
        backgroundColor: ChartColor.backgroundColorChart,
        data: salesData,
        fill: false
      }],
    };

    this.optionsPie = {
      legend: {
        position: legendChart,
      }
    };

  }

  changeTypeChart(type: string) {
    this.typeChart = type;
    this.typeChartTotal = type;
    if (type === 'line') {
      this.disButtonChart = true;
    } else {
      this.disButtonChart = false;
    }
  }

  getDatatoTable() {

    this.service.loadDshTableOrderData(this.page_model).subscribe((response:ResponseDshTableOrderSaleOrderPeriod)=> {
      this.page_model.table_order_data = response.data;
      this.page_model.temp_table_order_data = this.page_model.table_order_data;
      this.page_model.uuid = response.uuid;

      if(this.page_model.table_order_data.length > 0){
        this.dataNotFoundGridOrd = false;
      }else{
        this.dataNotFoundGridOrd = true;
      }

    });

    this.service.loadDshTableSaleData(this.page_model).subscribe((response:ResponseDshTableSaleOrderPeriod)=> {
      this.page_model.table_sale_data = response.data;
      this.page_model.temp_table_sale_data = this.page_model.table_sale_data;
      this.page_model.uuid = response.uuid;

      if(this.page_model.table_sale_data.length > 0){
        this.dataNotFoundGrid2 = false;
      }else{
        this.dataNotFoundGrid2 = true;
      }

    });

    this.service.loadDshTableTotalData(this.page_model).subscribe((response:ResponseDshSaleOrderPeriod)=> {
      this.page_model.table_total_data = response.data;
      this.page_model.temp_table_total_data = this.page_model.table_total_data;
      this.page_model.uuid = response.uuid;

      if(this.page_model.table_sale_data.length > 0){
        this.dataNotFoundGrid = false;
      }else{
        this.dataNotFoundGrid = true;
      }

    });
  }

  exportReport(flag) {
    // flag 1 = By invoice , flag 2 = By item
    let subTitle = [];
    let period: any;
    const newDate = new Date(this.labelDateFrom);
    let periodCode = this.page_model.form_model.period.controls['period'].value.parameterDetailPK.pmdtbdtbdentcd;
    // yesterday = moment(newDate).subtract().format('YYYY-MM-DD');

    if (this.periodLabel == '0D') {
      period = ` ${moment(newDate).subtract().format('YYYY-MM-DD')}`;
    } else if (this.periodLabel == '1D') {
      period = ` ${moment().subtract(1, 'days').format('YYYY-MM-DD')}`;
    } else {
      period = ` ${this.labelDateFrom} ${this.translateService.instant('DSHSAL100.M00058')} ${this.labelDateTo}`;
    }

    subTitle.push([`${this.translateService.instant('DSHSAL100.M00077')} ${this.translateService.instant('DSHSAL100.M00026')} ${this.periodLabel}${period}`]);
    if (flag == 1) {
      let header: any = {
        // 'เลขที่ออเดอร์'
        sales_order_no:this.translateService.instant('DSHSAL100.M00027'),
        // 'วันที่ออร์เดอร์'
        sales_order_date: this.translateService.instant('DSHSAL100.M00028'),
        // 'รหัสสินค้าจาก Seller'
        item_code_from_seller: this.translateService.instant('DSHSAL100.M00034'),
        // 'รหัสสินค้า KlangOne'
        system_item_code: this.translateService.instant('DSHSAL100.M00035'),
        // 'ชื่อสินค้า'
        item_desc: this.translateService.instant('DSHSAL100.M00036'),
        // 'จำนวน'
        order_quantity: this.translateService.instant('DSHSAL100.M00037'),
        // 'หน่วย'
        sku_unit: this.translateService.instant('DSHSAL100.M00060'),
        // ยอดขาย
        total_amount_inc_vat: `${this.translateService.instant('DSHSAL100.M00033')}(${this.currency.currencyCd})`,
        //Tracking No.
        tracking_no: this.translateService.instant('DSHSAL100.M00041'),
        // สถานะ Order
        sales_status_desc: this.translateService.instant('DSHSAL100.M00029'),
        // สถานะการเงิน
        payment_status_desc: this.translateService.instant('DSHSAL100.M00030'),
        // ช่องทางขาย
        sales_channel_desc: this.translateService.instant('DSHSAL100.M00045'),
        //เบอร์โทรศัพท์
        customer_phone: this.translateService.instant('DSHSAL100.M00075'),
        // ที่อยู่
        cus_address: this.translateService.instant('DSHSAL100.M00076')
      };
      let export_data_list = [];
      this.page_model.table_total_data.forEach(( sale_summary : DshSaleOrderDetailTotalData ) => {
        let export_data = {
          sales_order_no: sale_summary.sales_order_no,
          sales_order_date: this.thaidate.transform(sale_summary.sales_order_date, 'shortCommonFullTime'),
          item_code_from_seller: sale_summary.item_code_from_seller,
          system_item_code: sale_summary.system_item_code,
          item_desc: this.translateLablePipe.transform(sale_summary.item_desc_eng,sale_summary.item_desc_local),
          order_quantity: sale_summary.order_quantity,
          sku_unit: sale_summary.sku_unit,
          total_amount_inc_vat : this.decimalService.getRoundingDecimal(sale_summary.total_amount_inc_vat, this.method, this.roundToDecimal, this.decimal),
          tracking_no: sale_summary.tracking_no,
          sales_status_desc: this.translateLablePipe.transform(sale_summary.sales_status_desc_eng,sale_summary.sales_status_desc_local),
          payment_status_desc: this.translateLablePipe.transform(sale_summary.payment_status_desc_eng,sale_summary.payment_status_desc_local),
          sales_channel_desc: this.translateLablePipe.transform(sale_summary.sales_channel_desc_eng,sale_summary.sales_channel_desc_local),
          customer_phone: sale_summary.customer_phone,
          cus_address: this.translateLablePipe.transform(sale_summary.cus_address_eng,sale_summary.cus_address_local),
        }
        export_data_list.push(export_data);
      });
      this.exportExcelService.exportAsExcelFile(export_data_list,this.translateService.instant('DSHSAL100.M00026'),
        header,
        null,
        subTitle);


    } else if (flag == 2) {
      subTitle = [];
      subTitle.push([`${this.translateService.instant('DSHSAL100.M00077')} ${this.translateService.instant('DSHSAL100.M00025')} ${this.periodLabel}${period}`]);
      const header: any = {
        // รหัสสินค้าจาก Seller
        item_code_from_seller: this.translateService.instant('DSHSAL100.M00034'),
        // รหัสสินค้า KlangOne
        system_item_code: this.translateService.instant('DSHSAL100.M00035'),
        // ชื่อสินค้า
        item_desc: this.translateService.instant('DSHSAL100.M00036'),
        // จำนวน
        order_quantity: this.translateService.instant('DSHSAL100.M00037'),
        // หน่วย
        sku_unit: this.translateService.instant('DSHSAL100.M00060'),
        // ยอดขาย
        total_amount_exc_vat: `${this.translateService.instant('DSHSAL100.M00033')}(${this.currency.currencyCd})`,
      };
      let export_data_list = [];
      this.page_model.table_sale_data.forEach(( sale_item : DshSaleOrderDetailSaleData ) => {
        let export_data = {
          item_code_from_seller : sale_item.item_code_from_seller,
          system_item_code : sale_item.system_item_code,
          item_desc: this.translateLablePipe.transform(sale_item.item_desc_eng,sale_item.item_desc_local),
          order_quantity: sale_item.order_quantity,
          sku_unit : sale_item.sku_unit,
          total_amount_exc_vat : this.decimalService.getRoundingDecimal(sale_item.total_amount_exc_vat, this.method, this.roundToDecimal, this.decimal),
        }
        export_data_list.push(export_data);
      });
      this.exportExcelService.exportAsExcelFile(export_data_list,this.translateService.instant('DSHSAL100.M00025'),
        header,
        null,
        subTitle);
    } else if (flag == 3) {
      subTitle = [];
      subTitle.push([`${this.translateService.instant('DSHSAL100.M00077')} ${this.translateService.instant('DSHSAL100.M00024')} ${this.periodLabel}${period}`]);
      const header: any = {
        // เลขที่ออร์เดอร์
        sales_order_no: this.translateService.instant('DSHSAL100.M00027'),
        // วันที่ออร์เดอร์
        sales_order_date:  this.translateService.instant('DSHSAL100.M00028'),
        // จำนวน(ชิ้น)
        total_sales_qty: this.translateService.instant('DSHSAL100.M00044'),
        // ยอดขาย
        total_discount: `${this.translateService.instant('DSHSAL100.M00033')}(${this.currency.currencyCd})`,
        // สถานะ Order
        sales_status_desc: this.translateService.instant('DSHSAL100.M00029'),
        // สถานะการเงิน
        payment_status_desc: this.translateService.instant('DSHSAL100.M00030'),
        // ช่องทางการขาย
        sales_channel_desc: this.translateService.instant('DSHSAL100.M00045')
      };
      let export_data_list = [];
      this.page_model.table_order_data.forEach(( sale_order :  DshSaleOrderDetailOrderData ) => {
        let export_data = {
          sales_order_no: sale_order.sales_order_no,
          sales_order_date: this.thaidate.transform(sale_order.sales_order_date, 'shortCommonFullTime'),
          total_sales_qty : sale_order.total_sales_qty,
          total_discount : this.decimalService.getRoundingDecimal(sale_order.total_discount, this.method, this.roundToDecimal, this.decimal),
          sales_status_desc: this.translateLablePipe.transform(sale_order.sales_status_desc_eng,sale_order.sales_status_desc_local),
          payment_status_desc: this.translateLablePipe.transform(sale_order.payment_status_desc_eng,sale_order.payment_status_desc_local),
          sales_channel_desc: this.translateLablePipe.transform(sale_order.sales_channel_desc_eng,sale_order.sales_channel_desc_local),
        }
        export_data_list.push(export_data);
      });
      this.exportExcelService.exportAsExcelFile(export_data_list,
        this.translateService.instant('DSHSAL100.M00024'),
        header,
        null,
        subTitle);

    }
  }

  advSearchSaleSumOrderGrid() {
    this.page_model.table_order_data = this.page_model.temp_table_order_data;
    if (this.page_model.form_model.searchOrderGroupForm.get('sales_order_no').value) {
      this.page_model.table_order_data = this.page_model.table_order_data.filter(data => {
        return data.sales_order_no.toString().toLowerCase().indexOf(this.page_model.form_model.searchOrderGroupForm.get('sales_order_no').value.toLowerCase()) > -1;
      });
    }

    if (this.page_model.form_model.searchOrderGroupForm.get('sales_order_date').value) {
      if (this.page_model.form_model.searchOrderGroupForm.get('sales_order_date').value[0] && this.page_model.form_model.searchOrderGroupForm.get('sales_order_date').value[1] == null) {
        this.page_model.form_model.searchOrderGroupForm.get('sales_order_date').value[1] = this.page_model.form_model.searchOrderGroupForm.get('sales_order_date').value[0];
      } 
      const fromDate = this.page_model.form_model.searchOrderGroupForm.get('sales_order_date').value[0];
      const toDate = this.page_model.form_model.searchOrderGroupForm.get('sales_order_date').value[1];

      this.page_model.table_order_data = this.page_model.table_order_data.filter(data => {
        if((fromDate <= new Date(data.sales_order_date).setHours(0,0,0,0)) && (new Date(data.sales_order_date).setHours(0,0,0,0) <= toDate)) {
          return true;
        }
      });
    }

    if (this.page_model.form_model.searchOrderGroupForm.get('sales_channel').value) {
      this.page_model.table_order_data = this.page_model.table_order_data.filter(data =>
        this.page_model.form_model.searchOrderGroupForm.get('sales_channel').value.includes(data.sales_channel));
    }

    if (this.page_model.form_model.searchOrderGroupForm.get('sales_status').value) {
      this.page_model.table_order_data = this.page_model.table_order_data.filter(data =>
        this.page_model.form_model.searchOrderGroupForm.get('sales_status').value.includes(data.sales_status_code));
    }

    if (this.page_model.form_model.searchOrderGroupForm.get('payment_status').value) {
      this.page_model.table_order_data = this.page_model.table_order_data.filter(data =>
        this.page_model.form_model.searchOrderGroupForm.get('payment_status').value.includes(data.payment_status));
    }

    if (this.page_model.table_order_data.length == 0) {
      this.dataNotFoundGridOrd = true;
    } else {
      this.dataNotFoundGridOrd = false;
    }

    this.firstOrd = 0;
  }

  advSearchItemGrid() {
    this.page_model.table_sale_data = this.page_model.temp_table_sale_data;
    if (this.page_model.form_model.searchItmGroupForm.get('item_code_from_seller').value) {
      this.page_model.table_sale_data = this.page_model.table_sale_data.filter(data => {
        return (data.item_code_from_seller?data.item_code_from_seller:'').toLowerCase().indexOf(this.page_model.form_model.searchItmGroupForm.get('item_code_from_seller').value.toLowerCase()) > -1;
      });
    }

    if (this.page_model.form_model.searchItmGroupForm.get('system_item_code').value) {
      this.page_model.table_sale_data = this.page_model.table_sale_data.filter(data => {
        return (data.system_item_code?data.system_item_code:'').toLowerCase().indexOf(this.page_model.form_model.searchItmGroupForm.get('system_item_code').value.toLowerCase()) > -1;
      });
    }

    if (this.page_model.form_model.searchItmGroupForm.get('item_desc').value) {
      this.page_model.table_sale_data = this.page_model.table_sale_data.filter(data => {
        return (data.item_desc_eng?data.item_desc_eng:'').toLowerCase().indexOf(this.page_model.form_model.searchItmGroupForm.get('item_desc').value.toLowerCase()) > -1
        || (data.item_desc_local?data.item_desc_local:'').toLowerCase().indexOf(this.page_model.form_model.searchItmGroupForm.get('item_desc').value.toLowerCase()) > -1;
      });
    }

    if (this.page_model.table_sale_data.length == 0) {
      this.dataNotFoundGrid2 = true;
    } else {
      this.dataNotFoundGrid2 = false;
    }
    this.firstItm = 0;
  }

  advSearchInvoiceGrid() {

    this.page_model.table_total_data = this.page_model.temp_table_total_data;
    if (this.page_model.form_model.searchInvGroupForm.get('sales_order_no').value) {
      this.page_model.table_total_data = this.page_model.table_total_data.filter(data => {
        return data.sales_order_no.toString().toLowerCase().indexOf(this.page_model.form_model.searchInvGroupForm.get('sales_order_no').value.toLowerCase()) > -1;
      });
    }

    if (this.page_model.form_model.searchInvGroupForm.get('item_code_from_seller').value) {
      this.page_model.table_total_data = this.page_model.table_total_data.filter(data => {
        return (data.item_code_from_seller?data.item_code_from_seller:'').toLowerCase().indexOf(this.page_model.form_model.searchInvGroupForm.get('item_code_from_seller').value.toLowerCase()) > -1;
      });
    }

    if (this.page_model.form_model.searchInvGroupForm.get('system_item_code').value) {
      this.page_model.table_total_data = this.page_model.table_total_data.filter(data => {
        return (data.system_item_code?data.system_item_code:'').toString().toLowerCase().indexOf(this.page_model.form_model.searchInvGroupForm.get('system_item_code').value.toLowerCase()) > -1;
      });
    }



    if (this.page_model.form_model.searchInvGroupForm.get('item_desc').value) {
      this.page_model.table_total_data = this.page_model.table_total_data.filter(data => {
        return data.item_desc_eng.toLowerCase().indexOf(this.page_model.form_model.searchInvGroupForm.get('item_desc').value.toLowerCase()) > -1
        || data.item_desc_local.toLowerCase().indexOf(this.page_model.form_model.searchInvGroupForm.get('item_desc').value.toLowerCase()) > -1;
      });
    }

    if (this.page_model.form_model.searchInvGroupForm.get('sales_order_date').value) {
      if (this.page_model.form_model.searchInvGroupForm.get('sales_order_date').value[0] && this.page_model.form_model.searchInvGroupForm.get('sales_order_date').value[1] == null) {
        this.page_model.form_model.searchInvGroupForm.get('sales_order_date').value[1] = this.page_model.form_model.searchInvGroupForm.get('sales_order_date').value[0];
      }    
      const fromDate = this.page_model.form_model.searchInvGroupForm.get('sales_order_date').value[0];
      const toDate = this.page_model.form_model.searchInvGroupForm.get('sales_order_date').value[1]; 

      this.page_model.table_total_data = this.page_model.table_total_data.filter(data => {      
        if((fromDate <= new Date(data.sales_order_date).setHours(0,0,0,0)) && (new Date(data.sales_order_date).setHours(0,0,0,0) <= toDate)) {
          return true;
        }
      });
    }

    if (this.page_model.form_model.searchInvGroupForm.get('payment_complete_date').value) {
      if (this.page_model.form_model.searchInvGroupForm.get('payment_complete_date').value[0] && this.page_model.form_model.searchInvGroupForm.get('payment_complete_date').value[1] == null) {
        this.page_model.form_model.searchInvGroupForm.get('payment_complete_date').value[1] = this.page_model.form_model.searchInvGroupForm.get('payment_complete_date').value[0];
      }      
      const fromDate = this.page_model.form_model.searchInvGroupForm.get('payment_complete_date').value[0];
      const toDate = this.page_model.form_model.searchInvGroupForm.get('payment_complete_date').value[1]; 

      this.page_model.table_total_data = this.page_model.table_total_data.filter(data => {
     
        const dateCheck = moment(data.payment_complete_date,'DD/MM/YYYY').format('YYYY-MM-DD');
        if((fromDate <= new Date(dateCheck).setHours(0,0,0,0)) && (new Date(dateCheck).setHours(0,0,0,0) <= toDate) && data.payment_complete_date != null) {
          console.log('payment_complete_date',new Date(dateCheck).setHours(0,0,0,0));
          return true;
        }
      });
    }

    if (this.page_model.form_model.searchInvGroupForm.get('last_mile_status_date').value) {
      if (this.page_model.form_model.searchInvGroupForm.get('last_mile_status_date').value[0] && this.page_model.form_model.searchInvGroupForm.get('last_mile_status_date').value[1] == null) {
        this.page_model.form_model.searchInvGroupForm.get('last_mile_status_date').value[1] = this.page_model.form_model.searchInvGroupForm.get('last_mile_status_date').value[0];
      }   
      const fromDate = this.page_model.form_model.searchInvGroupForm.get('last_mile_status_date').value[0];
      const toDate = this.page_model.form_model.searchInvGroupForm.get('last_mile_status_date').value[1]; 

       this.page_model.table_total_data = this.page_model.table_total_data.filter(data => {

        const dateCheck = moment(data.last_mile_status_date,'DD/MM/YYYY').format('YYYY-MM-DD');
        if((fromDate <= new Date(dateCheck).setHours(0,0,0,0)) && (new Date(dateCheck).setHours(0,0,0,0) <= toDate) && data.last_mile_status_date != null) {
          return true;
        }
      });
    }
    if (this.page_model.form_model.searchInvGroupForm.get('payment_status').value) {
      this.page_model.table_total_data = this.page_model.table_total_data.filter(data =>
        this.page_model.form_model.searchInvGroupForm.controls['payment_status'].value.includes(data.payment_status));
    }




    let payment_method:any = [];
    if(this.page_model.form_model.searchInvGroupForm.get('payment_method').value){
      this.page_model.form_model.searchInvGroupForm.get('payment_method').value.forEach(data =>{
        payment_method.push(data.pmdtbdtxtv2);
      })

      if (payment_method) {
        this.page_model.table_total_data = this.page_model.table_total_data.filter(data =>
          payment_method.includes(data.payment_method_code));
      }
    }



    if (this.page_model.form_model.searchInvGroupForm.get('tracking_no').value) {
      this.page_model.table_total_data = this.page_model.table_total_data.filter(data => {
        return (data.tracking_no?data.tracking_no:'').toString().toLowerCase().indexOf(this.page_model.form_model.searchInvGroupForm.get('tracking_no').value.toLowerCase()) > -1;
      });
    }

    if (this.page_model.form_model.searchInvGroupForm.get('sales_status').value) {
      this.page_model.table_total_data = this.page_model.table_total_data.filter(data =>
        this.page_model.form_model.searchInvGroupForm.controls['sales_status'].value.includes(data.sales_status_code));
    }



    if (this.page_model.table_total_data.length == 0) {
      this.dataNotFoundGrid = true;
    } else {
      this.dataNotFoundGrid = false;
    }
    this.firstInv = 0;
  }

  ClearData(flag) {
    // flag 1 = By invoice , flag 2 = By item
    if (flag == 1) {
      this.dataNotFoundGrid = false;
      this.page_model.form_model.searchInvGroupForm.reset();
      this.page_model.table_total_data = this.page_model.temp_table_total_data;
      this.count = 0;
    } else if (flag == 2) {
      this.dataNotFoundGrid2 = false;
      this.page_model.form_model.searchItmGroupForm.reset();
      this.page_model.table_sale_data = this.page_model.temp_table_sale_data;
    } else if (flag == 3) {
      this.dataNotFoundGridOrd = false;
      this.page_model.form_model.searchOrderGroupForm.reset();
      this.page_model.table_order_data = this.page_model.temp_table_order_data;
      this.count = 0;
    }
  }

  onDatesRangeFilterSelected() {
    if (this.page_model.form_model.searchOrderGroupForm.get('orderDateForm').value[1]) {
      this.dateFilter.hideOverlay();
    }
  }

  setValueDate(){
    this.maxDate = new Date();
    this.maxDate.setMonth(this.maxDate.getMonth()+1, 0);
    this.minDate = new Date();
    this.minDate.setMonth(this.minDate.getMonth(), 1);
  }
}
