import { Component, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { FormGroup, FormControl } from '@angular/forms';
import { ExportExcelService } from 'src/app/shared/services/export-excel.service';
import * as moment from 'moment';
import { ParameterService } from 'src/app/shared/services/parameter.service';
import * as Chart from 'chart.js';
import { BarChartData, BarChartModel, ChartPie, MonthDataModel, PeriodModel, PieChartData, SaleSummaryPeriodModel, StockGrid, StockItem, ValueStock } from 'src/app/shared/model/dashboard-stock.model';
import { TotalSale } from 'src/app/shared/model/dashboard-overall.model';
import { DashboardStockService } from 'src/app/shared/services/dashboard-stock.service';
import { DashboardService } from 'src/app/shared/services/dshboard.service';
import { ChartColor } from 'src/app/shared/constant/chartcolor.constant';
import { ParameterWmsSerive } from 'src/app/shared/services/parameterWms.service';
import { TranslateService } from '@ngx-translate/core';
import { ParameterEntity } from 'src/app/shared//model/parameterwmsmodel';

import { decimalTypePurchase, decimalTypeSelling, decimalTypeRoundMethod, decimalTypeRoundToValue } from 'src/app/shared/constant/decimal.constant';
import { DecimalData } from '../../model/dsh-model';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { Merchandise, StockCardByPeriodAllModel, StockCardByPeriodModel } from 'src/app/shared/model/stock-card.model';
import { TempDDL } from '../model/dshstkfs100.model';

@Component({
  selector: 'app-dshstkfs100',
  templateUrl: './dshstkfs100.component.html',
  styleUrls: ['./dshstkfs100.component.scss']
})
export class Dshstkfs100Component implements OnInit {
  //Model data
  public decimalData:DecimalData = {} as DecimalData;
  stockDataByItem: Array<StockItem> = [] as Array<StockItem>;
  getStockItem: any = [];
  getStockItemAll: any = [];
  stockDataGrid: StockGrid = {} as StockGrid;
  valueStock: ValueStock = {} as ValueStock;
  saleSummaryPeriod : SaleSummaryPeriodModel = {} as SaleSummaryPeriodModel;
  StockCardData : Array<StockCardByPeriodModel> = [] as Array<StockCardByPeriodModel>;
  StockCardDataAll : Array<StockCardByPeriodAllModel> = [] as Array<StockCardByPeriodAllModel>;
  periodData : PeriodModel = {} as PeriodModel;
  totalStockqty: any;
  chartStock: Array<ChartPie> = [{}] as Array<ChartPie>;
  chartData:Array<PieChartData> = [{}] as Array<PieChartData>
  totalSale: TotalSale = {} as TotalSale;
  // Condition for display.
  dataNotFoundGrid: boolean = true as boolean;
  dataNotFoundBarChart: boolean = true as boolean;
  dataNotFoundPieChart: boolean = true as boolean;
  checkDataNotFoundPie: string = 'NotFound' as string;
  IsSearchByItem: boolean = false as boolean;
  IsSearchByDoc: boolean = false as boolean;
  typeChart: string = 'bar' as string;
  status: boolean;
  saleShow: any;
  optionsPie: any;
  optionsBar: any;
  chartPie: any;
  stockDataByDoc: any;
  firstInv: number = 0 as number;
  firstItm: number = 0 as number;
  disButtonChart: boolean = true as boolean;
  disabledYear: boolean = true as boolean;
  disabledMonth: boolean = true as boolean;
  //Decimal
  method: string;
  roundToDecimal: number;
  decimal: number = 0;
  // label display Data.
  periodLabel: string;
  labelDateFrom: any = moment().year((new Date()).getFullYear()).month((new Date()).getMonth()).startOf('month').format('YYYY-MM-DD');
  labelDateTo: any = new Date();
  month: any;
  year: any;
  period: any = moment().month((new Date()).getMonth()).format('MM') + String((new Date()).getFullYear());
  currentDate: any = moment(new Date()).format('YYYY-MM-DD');
  label = [];
  periodLast4month = [3, 2, 1, 0]
  admissionData = [];
  exportData = [];
  blanceData = [];

  // Advance Search And Form.
  criteriaSearchByItem = { item_product_code_seller: '', item_product_code_klangone: '', item_english_desc: '', main_merchandise_eng_desc: '', main_merchandise_code: []};
  criteriaSearchByItemAll={ item_product_code_seller: '', item_product_code_klangone: '', item_english_desc: '', main_merchandise_eng_desc: '', main_merchandise_code: []};
  invoiceDate: any;
  firstRow: number = 0 as number;
  itemType : String[] = [];
  searchByitemGroupForm = new FormGroup({
    barCodeForm: new FormControl(),
    onlineCodeForm: new FormControl(),
    prodNameForm: new FormControl(),
    categoryForm: new FormControl()
  });
  searchByitemGroupFormAll = new FormGroup({
    barCodeForm: new FormControl(),
    onlineCodeForm: new FormControl(),
    prodNameForm: new FormControl(),
    categoryForm: new FormControl()
  });
  searchBydocGroupForm = new FormGroup({
    docCodeForm: new FormControl(),
    docitemCodeForm: new FormControl(),
    docitemDescForm: new FormControl(),
    doccatagoryForm: new FormControl()
  });
  warehouse: string;
  seller: any;
  isRight: boolean = false as boolean;
  optionCountry: ParameterEntity[];
  optionYear: SelectItem[];
  optionMonth: SelectItem[];
  chartBarData: any;
  options: any;
  pageName: string;
  sellerLength: number;
  loadingFlag: boolean = false as boolean;
  disableCountry:boolean;
  paramToSeller:any = {country:true,warehouse:true};
  uuid:string = ""; 
  thaiMonth = ['DSHSTKF100.M00041','DSHSTKF100.M00042','DSHSTKF100.M00043','DSHSTKF100.M00044','DSHSTKF100.M00045','DSHSTKF100.M00046',
                'DSHSTKF100.M00047','DSHSTKF100.M00048','DSHSTKF100.M00049','DSHSTKF100.M00050','DSHSTKF100.M00051','DSHSTKF100.M00052'];

  optionMonthFull = ['DSHSTKF100.M00053','DSHSTKF100.M00054','DSHSTKF100.M00055','DSHSTKF100.M00056','DSHSTKF100.M00057','DSHSTKF100.M00058',
                    'DSHSTKF100.M00059','DSHSTKF100.M00060','DSHSTKF100.M00061','DSHSTKF100.M00062','DSHSTKF100.M00063','DSHSTKF100.M00064'];

  programId: string = 'P13001' as string;
  currency = { currencyCd: '', currencyDesc: '' };
  optionItemType: TempDDL[];
  optionsMerchandise : Array<Merchandise> = [] as Array<Merchandise>;
  optionsMerchandiseAll : Array<Merchandise> = [] as Array<Merchandise>;

  constructor(private dshstkService: DashboardStockService,
    private exportExcelService: ExportExcelService,
    private ParameterWmsSerive: ParameterWmsSerive,
    private prmService: ParameterService,
    private dshService: DashboardService,
    private translateService: TranslateService,
    private autService: AuthenticationService,) {
    this.pageName = localStorage.getItem(this.programId);
    this.valueStock.stockDay = 0;
    this.totalSale.saleDay = 0;
    this.totalSale.saleMonth = 0;
    this.totalSale.saleQ1 = 0;
    this.totalStockqty=0;
    this.saleSummaryPeriod.sale_qty_this_month = 0;
    this.saleSummaryPeriod.sale_qty_three_month =0;
    this.saleSummaryPeriod.sale_qty_today=0;
    this.saleSummaryPeriod.sale_qty_this_year=0;
    this.status = false;
    this.getLangLabelDsh();
    this.getLangOptionMonth();
  }


  async getLangLabelDsh(){
      this.thaiMonth = await this.translateMessage(this.thaiMonth);
  }

  async getLangOptionMonth(){
      this.optionMonthFull = await this.translateMessage(this.optionMonthFull);
  }

  translateMessage(langLabel) {
    let msgLang: any[] = [];
    for (let i = 0; i < langLabel.length; i++) {
      this.translateService.get(langLabel[i]).subscribe(async res => {
        msgLang.push(await res);
      });
    }
    return msgLang;
  }

  ngOnInit() {
    if (window.screen.width <= 640) {
      this.isRight = false;
    } else {
      this.isRight = true;
    }
    this.autService.getParameterWmsCountry().subscribe((response) => {
      this.optionCountry = JSON.parse(JSON.stringify(response));
      
      if(this.optionCountry==null||this.optionCountry.length == 0){
        this.disableCountry = true;
      }
      else if(this.optionCountry.length == 1){
        this.warehouse = this.optionCountry[0].value;
        this.getCurrency(this.optionCountry[0].parameterData.entry_code);
        this.paramToSeller = {country:this.warehouse,warehouse:null}
        this.disableCountry = true;
      }
      
    }, err => {
      console.log(err);
    });
  }
  
  getSeller(seller) {
    this.StockCardData = [];
    this.StockCardDataAll=[];
    
    
    this.seller = seller.seller;
    this.sellerLength = seller.length;
    
      if (this.warehouse) {
        this.getCurrency(this.warehouse);
        this.getFormatNumber(this.warehouse);
        this.year = '';
        this.month = '';
        this.checkDataNotFoundPie = 'NotFound';
        this.dataNotFoundGrid = true;
        this.dataNotFoundPieChart = true;
        this.dataNotFoundBarChart = true;
        this.IsSearchByItem = false;
        this.chartStock = null;
        this.getYear();
        this.disButtonChart = false;
        this.getStockValueData();
        this.getSaleSummaryData();
        this.getTotalSale();
      }
  }

  wareHouse(page) {
    this.saleSummaryPeriod.sale_qty_this_month = 0;
    this.saleSummaryPeriod.sale_qty_three_month =0;
    this.saleSummaryPeriod.sale_qty_today=0;
    this.saleSummaryPeriod.sale_qty_this_year=0;
    this.warehouse = page.value;
    this.paramToSeller = {country:this.warehouse,warehouse:null}
    this.getCurrency(this.warehouse);
    if (this.sellerLength > 1) {
      this.seller = null;
      this.year = '';
      this.month = '';
      this.checkDataNotFoundPie = 'NotFound';
      this.dataNotFoundGrid = true;
      this.dataNotFoundPieChart = true;
      this.dataNotFoundBarChart = true;
      this.IsSearchByItem = false;
      this.chartStock = null;
      this.disButtonChart = false;
      this.StockCardData =[];
      this.StockCardDataAll =[];
      
    } else {
      this.year = '';
      this.month = '';
      this.checkDataNotFoundPie = 'NotFound';
      this.dataNotFoundGrid = true;
      this.dataNotFoundPieChart = true;
      this.dataNotFoundBarChart = true;
      this.IsSearchByItem = false;
      this.chartStock = null;
      this.disButtonChart = false;
      this.getStockValueData();
      this.getSaleSummaryData();
      this.getTotalSale();
      
    }
  }

  getCurrency(country) {
    this.ParameterWmsSerive.getParameterWmsBytableNoAndCode(105, country).subscribe((response) => {
      this.ParameterWmsSerive.getParameterWmsBytableNoAndCode(9146, response[0].parameterData.parameter_v6).subscribe((currencyData) => {       
          this.currency.currencyCd = currencyData[0].parameterData.parameter_v1;
          this.currency.currencyDesc =  response[0].parameterData.parameter_v6 ;
      });
    });
  }

  getTotalSale() {
    this.dshService.getTotalSale(this.warehouse, this.seller).subscribe(response => {
      this.totalSale = response[0];
    });
  }

  getFormatNumber(warehouse) {
    this.prmService.getvprmdtltolableByCountry(104, warehouse).subscribe(response => {
      let decimalData = JSON.parse(JSON.stringify(response));
      this.decimalData.dataTypeDecimalPurchase = decimalData.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == decimalTypePurchase);
      this.decimalData.dataTypeDecimalSelling = decimalData.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == decimalTypeSelling);
      this.decimalData.dataTypeDecimalRoundMethod = decimalData.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == decimalTypeRoundMethod);
      this.decimalData.dataTypeDecimalRoundToValue = decimalData.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == decimalTypeRoundToValue);
      this.decimal = this.decimalData.dataTypeDecimalSelling[0].value.pmdtbdnumv1;
      this.method = this.decimalData.dataTypeDecimalRoundMethod[0].value.pmdtbdtxtv1
      this.roundToDecimal = this.decimalData.dataTypeDecimalRoundToValue[0].value.pmdtbdnumv1;
    }, err => {
      console.log(err);
    });
  }

  getStockCardDataTable(){
    
    this.dshstkService.getStockCardByPeriod(this.warehouse,this.seller,this.month,this.year,this.uuid).subscribe((res)=>{
          this.StockCardData = res.data;   
          this.optionsMerchandise = res.merchandise;        
    });
    
  }
  
  getDatatoTable(chartStock) {
    this.dshstkService.getStockCardByPeriod(this.warehouse,this.seller,this.month,this.year,this.uuid).subscribe((response)=>{
      this.StockCardData  = response.data;
      //this.stockDataByItem = this.StockCardData;
      this.getStockItem = this.stockDataByItem;
      if (chartStock == null) {
        if (this.StockCardData.length == 0) {
          this.dataNotFoundGrid = true;
          this.dataNotFoundPieChart = true;
          this.dataNotFoundBarChart = true;
        } else {
          this.dataNotFoundGrid = false;
          this.dataNotFoundPieChart = true;
          this.dataNotFoundBarChart = false;
        }
      } else {
        this.dataNotFoundGrid = false;
        this.dataNotFoundPieChart = false;
        this.dataNotFoundBarChart = false;
      }
      this.getStockItem = JSON.parse(JSON.stringify(this.StockCardData)).map(response => {
        for (const value in response) {
          if (response[value] == null) {
            response[value] = '';
          }
        }
        return response;
      });
    });
  }

  filterData() {
    this.StockCardData = this.getStockItem;
    this.criteriaSearchByItem.item_product_code_seller = this.criteriaSearchByItem.item_product_code_seller == null ? '' : this.criteriaSearchByItem.item_product_code_seller;
    this.criteriaSearchByItem.item_product_code_klangone = this.criteriaSearchByItem.item_product_code_klangone == null ? '' : this.criteriaSearchByItem.item_product_code_klangone;
    this.criteriaSearchByItem.item_english_desc = this.criteriaSearchByItem.item_english_desc == null ? '' : this.criteriaSearchByItem.item_english_desc;

    this.StockCardData = this.StockCardData.filter(data => {
      return (
        (data.item_product_code_seller.toString().toLowerCase().indexOf(this.criteriaSearchByItem.item_product_code_seller.toLowerCase()) > -1) &&
        (data.item_product_code_klangone.toString().toLowerCase().indexOf(this.criteriaSearchByItem.item_product_code_klangone.toLowerCase()) > -1) &&
        (data.item_english_desc.toString().toLowerCase().indexOf(this.criteriaSearchByItem.item_english_desc.toLowerCase()) > -1)
      );
    });

    let merchandise_code:any = [];
    if(this.criteriaSearchByItem.main_merchandise_code.length > 0){
      this.criteriaSearchByItem.main_merchandise_code.forEach(data =>{
        merchandise_code.push(data);
      });
      if (merchandise_code) {
        this.StockCardData = this.StockCardData.filter(data =>
          merchandise_code.includes(data.main_merchandise_code)
        );
      }
    }

    if (this.StockCardData.length === 0) {
      this.dataNotFoundGrid = true;
    } else {
      this.dataNotFoundGrid = false;
      this.firstRow = 0;
    }

   

    
  }

  filterDataAll(){

    this.StockCardDataAll = this.getStockItemAll;
    this.criteriaSearchByItemAll.item_product_code_seller = this.criteriaSearchByItemAll.item_product_code_seller == null ? '' : this.criteriaSearchByItemAll.item_product_code_seller;
    this.criteriaSearchByItemAll.item_product_code_klangone = this.criteriaSearchByItemAll.item_product_code_klangone == null ? '' : this.criteriaSearchByItemAll.item_product_code_klangone;
    this.criteriaSearchByItemAll.item_english_desc = this.criteriaSearchByItemAll.item_english_desc == null ? '' : this.criteriaSearchByItemAll.item_english_desc;
    
    this.StockCardDataAll = this.StockCardDataAll.filter(data =>{
      return(
        (data.item_product_code_seller.toString().toLowerCase().indexOf(this.criteriaSearchByItemAll.item_product_code_seller.toLowerCase()) > -1) &&
        (data.item_product_code_klangone.toString().toLowerCase().indexOf(this.criteriaSearchByItemAll.item_product_code_klangone.toLowerCase()) > -1) &&
        (data.item_english_desc.toString().toLowerCase().indexOf(this.criteriaSearchByItemAll.item_english_desc.toLowerCase()) > -1)
      )
    });

    let merchandiseAll_code:any = [];
    if(this.criteriaSearchByItemAll.main_merchandise_code.length > 0){
      this.criteriaSearchByItemAll.main_merchandise_code.forEach(data =>{
        merchandiseAll_code.push(data);
      });
      if (merchandiseAll_code) {
        this.StockCardDataAll = this.StockCardDataAll.filter(data =>
          merchandiseAll_code.includes(data.main_merchandise_code)
        );
      }
    }

    if (this.StockCardDataAll.length === 0) {
      this.dataNotFoundGrid = true;
    } else {
      this.dataNotFoundGrid = false;
      this.firstRow = 0;
    }
  }

  exportReport(flag: number) {
      const subTitle = [];
      const labelFrom = moment(`01/${this.month}/${this.year}`, 'DD/MM/YYYY', true).format('DD/MM/YYYY');
      const labelTo = moment(labelFrom, 'DD/MM/YYYY', true).endOf('month').format('DD/MM/YYYY');
    if (flag === 1) {
      
      subTitle.push([`${this.translateService.instant('DSHSTKF100.M00065')} ${labelFrom} ${this.translateService.instant('DSHSTKF100.M00025')} ${labelTo}`]);
      const header: any = {
        //รหัสสินค้าจาก Seller
        item_product_code_seller: this.translateService.instant('DSHSTKF100.M00035'),
        //รหัสสินค้า KlangOne
        item_product_code_klangone: this.translateService.instant('DSHSTKF100.M00036'),
        //ชื่อสินค้า
        item_english_desc: this.translateService.instant('DSHSTKF100.M00037'),
        //หน่วย
        item_stock_unit: this.translateService.instant('DSHSTKF100.M00038'),
        //ประเภท
        main_merchandise_eng_desc: this.translateService.instant('DSHSTKF100.M00008'),
        //แบนรด์
        item_brand: this.translateService.instant('DSHSTKF100.M00009'),
        //จำนวนเริ่มต้น
        balance_forward_qty: this.translateService.instant('DSHSTKF100.M00010'),
        //จำนวนได้รับ (รับเข้า-รับคืน)
        receive_qty: this.translateService.instant('DSHSTKF100.M00011'),
        //จำนวนส่งออก (ขาย-เสีย-ส่งกลับ)
        sale_qty: this.translateService.instant('DSHSTKF100.M00012'),
        //จำนวนคงเหลือ
        ending_balance_qty: this.translateService.instant('DSHSTKF100.M00013'),
      };
      let export_data_list = [];
      this.StockCardData.forEach(element =>{
        let export_data = {
          item_product_code_seller:element.item_product_code_seller,
          item_product_code_klangone:element.item_product_code_klangone,
          item_english_desc:element.item_english_desc,
          item_stock_unit:element.item_stock_unit,
          main_merchandise_eng_desc:element.main_merchandise_eng_desc,
          item_brand:element.item_brand,
          balance_forward_qty:element.balance_forward_qty,
          receive_qty:element.receive_qty,
          sale_qty:element.sale_qty,
          ending_balance_qty:element.ending_balance_qty
        }
        export_data_list.push(export_data);
      })

      this.exportExcelService.exportAsExcelFile(export_data_list, this.translateService.instant('DSHSTKF100.M00065'), header, null, subTitle);
     //this.StockCardData.shift();
    }else if(flag === 2){
      
      subTitle.push([`${this.translateService.instant('DSHSTKF100.M00065')} ${labelFrom} ${this.translateService.instant('DSHSTKF100.M00025')} ${labelTo}`]);
      const header: any = {

        //รหัสโกดังสินค้า
        wh_code: 'โกดัง',
        //รหัสสินค้าจาก Seller
        item_product_code_seller: this.translateService.instant('DSHSTKF100.M00035'),
        //รหัสสินค้า KlangOne
        item_product_code_klangone: this.translateService.instant('DSHSTKF100.M00036'),
        //ชื่อสินค้า
        item_english_desc: this.translateService.instant('DSHSTKF100.M00037'),
        //หน่วย
        item_stock_unit: this.translateService.instant('DSHSTKF100.M00038'),
        //ประเภท
        main_merchandise_eng_desc: this.translateService.instant('DSHSTKF100.M00008'),
        //แบนรด์
        item_brand: this.translateService.instant('DSHSTKF100.M00009'),
        //จำนวนเริ่มต้น
        balance_forward_qty: this.translateService.instant('DSHSTKF100.M00010'),
        //จำนวนได้รับ (รับเข้า-รับคืน)
        receive_qty: this.translateService.instant('DSHSTKF100.M00011'),
        //จำนวนส่งออก (ขาย-เสีย-ส่งกลับ)
        sale_qty: this.translateService.instant('DSHSTKF100.M00012'),
        //จำนวนคงเหลือ
        ending_balance_qty: this.translateService.instant('DSHSTKF100.M00013'),
      };
      let export_data_list = [];
      this.StockCardDataAll.forEach(element =>{
        let export_data = {
          wh_code:element.wh_code,
          item_product_code_seller:element.item_product_code_seller,
          item_product_code_klangone:element.item_product_code_klangone,
          item_english_desc:element.item_english_desc,
          item_stock_unit:element.item_stock_unit,
          main_merchandise_eng_desc:element.main_merchandise_eng_desc,
          item_brand:element.item_brand,
          balance_forward_qty:element.balance_forward_qty,
          receive_qty:element.receive_qty,
          sale_qty:element.sale_qty,
          ending_balance_qty:element.ending_balance_qty
        }
        export_data_list.push(export_data);
      })

      this.exportExcelService.exportAsExcelFile(export_data_list, this.translateService.instant('DSHSTKF100.M00065'), header, null, subTitle);
    }
  }

  ClearData() {
    this.dataNotFoundGrid = false;
    this.searchByitemGroupForm.reset();
    this.searchByitemGroupFormAll.reset();
    this.StockCardData = this.getStockItem;
    this.StockCardDataAll = this.getStockItemAll;
  }

  getDatatoChartPie() {
    const main_merchandise_eng_desc: any = [];
    const qty: any = [];
    this.dshstkService.getPieChartSale(this.warehouse,this.seller,this.month,this.year,this.uuid).subscribe(response => {
      this.chartData = response.data;
      
      if (this.chartData.length == 0) {
        this.checkDataNotFoundPie = 'NotFound';
        this.dataNotFoundPieChart = true;
        this.getDatatoTable(this.chartStock);
      } else {
        this.chartData.forEach(element => {
          main_merchandise_eng_desc.push(element.item_local_desc);
          qty.push(element.ending_balance_qty);
        });
        if (main_merchandise_eng_desc && qty) {
          this.createChartTopTen(main_merchandise_eng_desc, qty);
          this.getDatatoTable(this.chartData);
        } else {
          this.dataNotFoundBarChart = true;
        }
        this.checkDataNotFoundPie = 'Data';
      }
    });
  }

  getDataChartCouple() {
    
    this.dshstkService.getBarChartSale(this.warehouse,this.seller,this.month,this.year,this.uuid,this.itemType).subscribe((response: BarChartModel) =>{
      this.chartBarData = response.data;
      
      this.prePairDataBarChar(this.chartBarData);
      this.getTotalQty(this.chartBarData);
    });

  }

  async prePairDataBarChar(chartBarData: Array<BarChartData>) {
    await this.getLangLabelDsh();
    let makeDate = new Date(this.labelDateFrom);
    this.label = [];
    this.admissionData = [];
    this.exportData = [];
    this.blanceData = [];
    const curYear = moment().year();

    this.periodLast4month.forEach(period => {
        makeDate.setMonth(makeDate.getMonth() - period);
        const year = makeDate.getFullYear();
        const shortYear = year.toString().substring(2);
        const monthLabel = this.translateService.instant(this.thaiMonth[makeDate.getMonth()]);
        // console.log('monthLabel', monthLabel);
        // console.info(this.thaiMonth[0])
        const displayYear = this.thaiMonth[0] == 'ม.ค.' ? (year + 543) : year;
        this.label.push(`${monthLabel} ${displayYear.toString().substring(2)}`);
        let data = chartBarData.find((e: BarChartData) => {
            const dataMonth = moment(e.dates, "YYYY-MM-DD").month();
            const dataYear = moment(e.dates, "YYYY-MM-DD").year();
            return dataMonth === makeDate.getMonth() && dataYear === year;
        });
        if (data) {
            this.admissionData.push(data.receive_qty);
            this.exportData.push(data.sale_qty);
            this.blanceData.push(data.ending_balance_qty);
        } else {
            this.admissionData.push(0);
            this.exportData.push(0);
            this.blanceData.push(0);
        }

        makeDate = new Date(this.labelDateFrom);
    });

    this.createChartCouple();
  }

  createChartCouple() {
    this.chartBarData = {
      labels: this.label,
      datasets: [
        {
          label: this.translateService.instant('DSHSTKF100.M00066'),
          backgroundColor: 'rgb(0,55,65)',
          data: this.admissionData,
          fill: false,
          borderColor: 'rgb(0,55,65)'
        },
        {
          label: this.translateService.instant('DSHSTKF100.M00067'),
          backgroundColor: 'rgb(245,9,145)',
          data: this.exportData,
          fill: false,
          borderColor: 'rgb(245,9,145)'
        }, {
          label: this.translateService.instant('DSHSTKF100.M00068'),
          backgroundColor: 'rgb(230,175,0)',
          data: this.blanceData,
          fill: false,
          borderColor: 'rgb(230,175,0)'
        }
      ],
    };

    Chart.defaults.global.defaultFontFamily = 'kanitmedium';
    let piecesWord = this.translateService.instant('DSHSTKF100.M00022');
    
    this.optionsBar = {
      legend: {
        position: 'top',
        labels: {
          fontFamily: 'kanitmedium'
        }
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            return `${tooltipItem.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} ${piecesWord}`;
          }
        }
      },
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true,
            userCallback: function (value, index, values) {
              value = value.toString();
              value = value.split(/(?=(?:...)*$)/);
              value = value.join(',');
              return value;
            }
          }
        }],
        xAxes: [{
          gridLines: {
            color: 'rgb(64,64,64)',
            display: false
          },
          ticks: {
            fontColor: 'rgb(64,64,64)',
          }
        }]
      }
    };
  }

  createChartTopTen(main_merchandise_eng_desc, qty) {
    let legendChart: any;
    if (window.screen.width >= 640 && window.screen.width < 1025) {
      legendChart = false;
    } else if (window.screen.width > 1024) {
      legendChart = 'right';
    }

    this.chartPie = {
      labels: main_merchandise_eng_desc,
      datasets: [{
        backgroundColor: ChartColor.backgroundColorChart,
        data: qty,
        fill: false,
      }],
    };

    this.optionsPie = {
      legend: {
        position: legendChart,
      }
    };
  }

  getStockValueData() {
    this.dshstkService.getStockValue(this.warehouse, this.seller).subscribe(response => {
      if (response[0] == null) {
        this.valueStock.stockDay = 0;
      } else {
        this.valueStock = response[0];
      }
    });
  }

  getSaleSummaryData(){
      this.dshstkService.getSaleSummaryPeriod(this.warehouse, this.seller,this.uuid).subscribe(response => {
        this.saleSummaryPeriod = response.data;

      });
  }

  changeTypeChart(type) {
    this.typeChart = type;
    if (type == 'line') {
      this.disButtonChart = true;
      this.createChartCouple();
    } else {
      this.disButtonChart = false;
      this.createChartCouple();
    }
  }

  getTotalQty(chartBarData:any) {    
    this.totalStockqty=chartBarData.datasets[2].data[3];

  }

  

  getStockCardAllDataTable(){
    
    this.dshstkService.getStockCardByPeriodAll(this.warehouse,this.seller,this.month,this.year,this.uuid).subscribe((res)=>{
          this.StockCardDataAll = res.data;
          this.optionsMerchandiseAll = res.merchandise;
          this.optionsMerchandiseAll.sort((a,b) => a.value.localeCompare(b.value));
          this.getStockItemAll = JSON.parse(JSON.stringify(this.StockCardDataAll)).map(response => {
            for (const value in response) {
              if (response[value] == null) {
                response[value] = '';
              }
            }
            return response;
          });

    });
  }

  getYear() {
    this.dshstkService.getPeriodData().subscribe((response:PeriodModel)=>{
    
      this.periodData = response;
      
      this.optionYear = this.periodData.yearList;
      if (this.optionYear.length == 1) {
        this.year = this.optionYear[0].value;
        this.disabledYear = true;
        this.getMonth();
      }else if(this.optionYear.length == 0){
        this.disabledYear = true;
        this.disabledMonth = true;
      } else{
        this.disabledYear = false;
      }
    });
   
  }

  getMonth() {
    
    if(this.year){
      var monthDataModel:Array<MonthDataModel> = this.periodData.monthData.filter((e:MonthDataModel)=>e.year==this.year);
      monthDataModel[0].monthList.forEach(data => {
          data.label= this.translateService.instant(this.optionMonthFull[+data.value - 1]);
      });

      this.optionMonth = monthDataModel[0].monthList;
      if (this.optionMonth.length == 1) {
        this.month = this.optionMonth[0].value;
        this.period = this.month + this.year;
        this.periodLabel = this.thaiMonth[this.month - 1];
        this.label = [];
        this.admissionData = [];
        this.exportData = [];
        this.blanceData = [];
        this.getDatatoChartPie();
        this.getDataChartCouple();
        this.getLabelDate();
        this.getStockCardAllDataTable();
        this.disabledMonth = true;
      } else {
        this.disabledMonth = false;
      }
    }

  }

  selectedMonth(month) {

    this.month = month;
    this.period = this.month + this.year;
    this.periodLabel = this.translateService.instant(this.thaiMonth[this.month - 1]);
    this.label = [];
    this.admissionData = [];
    this.exportData = [];
    this.blanceData = [];
    this.getDatatoChartPie();
    this.getDataChartCouple();
    this.getLabelDate();
    this.getStockCardDataTable();
    this.getStockCardAllDataTable();

    this.ParameterWmsSerive.getParameterWmsCheckbox(200).subscribe((response) => {
      this.optionItemType = JSON.parse(JSON.stringify(response));
    }, err => {
      console.log(err);
    });
  }

  selectedYear(year) {
    this.year = year;
    this.month = '';
    this.getMonth();

  }

  getLabelDate() {
    this.labelDateFrom = moment().year(this.year).month(this.month - 1).startOf('month').format('YYYY-MM-DD');
    if (moment().month((new Date()).getMonth()).format('MM') == this.month && String((new Date()).getFullYear()) == this.year) {
      this.labelDateTo = (new Date());
    } else {
      this.labelDateTo = moment().year(this.year).month(this.month - 1).endOf('month').format('YYYY-MM-DD');
    }
  }
}


