import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { LoaderState } from '../model/loader-state.model';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private loadingCount = 0; // ตัวแปรนับจำนวน Service ที่กำลังโหลด
  private loaderSubject = new BehaviorSubject<LoaderState>({ show: false });

  loaderState = this.loaderSubject.asObservable();

  constructor() {}

  show() {
    this.loadingCount++;
    this.updateLoaderState();
  }

  hide() {
    if (this.loadingCount > 0) {
      this.loadingCount--;
    }
    this.updateLoaderState();
  }

  private updateLoaderState() {
    this.loaderSubject.next({ show: this.loadingCount > 0 });
  }
}
